/**
 * Site header
 */
.site-header {
    border-top: 5px solid $grey-color-dark;
    //border-top: 5px solid #1a6ad2;
    //border-bottom: 1px solid $grey-color-light;
    padding-bottom: 2px;
    border-bottom: 4px solid #2a7ae2;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;

    background-color: $yummy-blue;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
    }

    .active {
        //background-color: #4898ff;
        //border-bottom: solid 10px #58a8ff;
        border-bottom: solid 8px #2a55c2;
    }

    a {
        padding: 8px 20px;
        text-decoration: none;
        color: white; // $text-color;
        line-height: $base-line-height;
        font-weight: 500;
        //position: relative;
        //top: 4px;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 10px;
        }
        margin-top: 20px;

        border-bottom: solid 8px $yummy-blue;
    }

    a:hover {
        //background-color: #58a8ff;
        //border-bottom: solid 10px #98c8ff;
        border-bottom: solid 8px #2848bf;
    }

//    /*@include media-query($on-palm) {
//        position: absolute;
//        top: 9px;
//        right: 30px;
//        background-color: $background-color;
//        border: 1px solid $grey-color-light;
//        border-radius: 5px;
//        text-align: right;
//
//        .menu-icon {
//            display: block;
//            float: right;
//            width: 36px;
//            height: 26px;
//            line-height: 0;
//            padding-top: 10px;
//            text-align: center;
//
//            > svg {
//                width: 18px;
//                height: 15px;
//
//                path {
//                    fill: $grey-color-dark;
//                }
//            }
//        }
//
//        .trigger {
//            clear: both;
//            display: none;
//        }
//
//        &:hover .trigger {
//            display: block;
//            padding-bottom: 5px;
//        }
//
//        .page-link {
//            display: block;
//            padding: 5px 10px;
//        }
//    }*/
}



/**
 * Site footer
 */
.site-footer {
    //border-top: 1px solid $grey-color-light;
    padding: $spacing-unit/2 0;
    text-align: center;
    background-color: $yummy-blue;
    color: #fff;
    font-weight: 200;
    //font-size: smaller;
    a {
        color: #fff;
        border-bottom: 2px solid $yummy-blue;
        font-weight: 800;
    }
    a:hover {
        color: #fff;
        text-decoration: none;
        border-bottom: 2px solid #fff;
    }
    a:visited {
        color: #fff;
    }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

//.contact-list,
//.social-media-list {
//    list-style: none;
//    margin-left: 0;
//}
//
//.footer-col-wrapper {
//    font-size: 15px;
//    color: $grey-color;
//    margin-left: -$spacing-unit / 2;
//    @extend %clearfix;
//}
//
//.footer-col {
//    float: left;
//    margin-bottom: $spacing-unit / 2;
//    padding-left: $spacing-unit / 2;
//}
//
//.footer-col-1 {
//    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
//    width:         calc(35% - (#{$spacing-unit} / 2));
//}
//
//.footer-col-2 {
//    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
//    width:         calc(20% - (#{$spacing-unit} / 2));
//}
//
//.footer-col-3 {
//    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
//    width:         calc(45% - (#{$spacing-unit} / 2));
//}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



.home {
    float: left;
    width: 100%;
    max-width: 500px;
}


/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 32px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.archive-aside {
    float: left;
    margin-top: 40px;
    margin-left: 60px;
}

.pagination {
  //padding: 10px 0;
  text-align: center;
  clear: both;
}

.post-teaser {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}

.pagination-older {
    display: block;
    float: left;
}

.pagination-newer {
    display: block;
    float: right;
}

.related-links {
    border-top: 1px solid #ddd;
    margin-top: 12px;
    margin-bottom: $spacing-unit;
    span {
      margin-left: 20px;
    }
}

#comments {
    border-top: 1px solid #ddd;
    margin-top: 12px;
}

.comment-container {
    border: 1px solid #ddd;
    margin-bottom: 12px;
    padding-top: 8px;
    padding-left: 8px;
}

.comment-header {
    margin-bottom: 12px;
}

.comment-nick {
    display: inline;
}

.comment-date {
    font-size: $small-font-size;
    color: $grey-color;
}

.comment-body {
    padding: 4px 12px;
}

.comment-indent {
    margin-left: 40px;
}

.comment-submit-wrapper {
    background-color: #eee;
    padding: 10px;
}

#comment-caveat {
    line-height: normal;
}

input, textarea {
    border: 1px solid #aaa;
    font-family: Verdana,Tahoma,Arial,Sans-Serif;
    font-size: 1em;
    margin: 0;
    padding: 4px;
}

textarea {
    max-width: 93%;
}

input[type="submit"] {
    background-color: #ccc;
    border: 1px solid #888;
    color: #444;
    font-size: 0.9em;
    //margin: 0 0 16px;
    padding: 4px 30px;
    //width: 140px;
}

